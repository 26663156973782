@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --ion-color-primary: #263444;
  --ion-color-primary-rgb: rgb(38, 52, 68);
  --ion-color-primary-shade: #132233;
  --ion-color-primary-tint: #384655;
}

.sc-ion-input-ios {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.alert-radio-label {
  white-space: normal !important;
  text-overflow: clip !important;
}

.select-text.select-placeholder {
  color: black;
  opacity: 1 !important;
}

ion-select label {
  word-break: normal !important;
  white-space: normal !important;
  text-overflow: clip !important;
}

@media screen and (min-width: 1280px) {
  ion-content {
    --padding-end: 15vw;
    --padding-start: 15vw;
  }

  ion-toolbar {
    --padding-end: 15vw;
    --padding-start: 15vw;
  }
}